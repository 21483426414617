@use "../Variables.scss";

.byep-block {
    &.loading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &.loaded {
        .byep-main {
            margin: 0 auto;
        }
    }
}