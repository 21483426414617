﻿@import "Variables.scss";

.video-popup {
    display: none;

    &.is-open {
        display: block;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($adtalemModalBackground, .8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1010;
    }

    &__container {
        max-width: 900px;
        width: 100%;
        padding-block: 2.5rem;
        padding-inline: 0.5rem;
    }

    &__close {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: $white;
        font-size: 1.75rem;
        line-height: 1;
        padding: 0;
        width: 100%;
        text-align: end;
        height: 44px;
        line-height: 44px;
        font-weight: $fontWeightSemiBold;
    }

    &__content {
        .content-video {

            iframe {
                aspect-ratio: 16 / 9;
                height: auto;
                width: 100%;
                box-shadow: 0 0 0.5rem rgba($black, .6);
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
    }
}

.popup {
    display: none;

    &.is-open {
        display: block;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($adtalemModalBackground, .8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1010;
    }

    &__container {
        max-width: 900px;
        width: 100%;
        padding-block: 2.5rem;
        padding-inline: 1.5rem;
        background-color: $white;
        border-radius: 0.5rem;
    }

    &__header {
        position: relative;

        .header__close {
            position: absolute;
            top: 0;
            right: 0;
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: $adtalemCyan3;
            font-size: 2rem;
            line-height: 1;
            padding: 0;
            width: fit-content;
            text-align: end;
            font-weight: $fontWeightSemiBold;
        }
    }

    &__content {
        margin-bottom: 1.5rem;

        *:last-child {
            padding-block-end: 0;
            margin-block-end: 0;
        }
    }

    &__footer {
    }
}