﻿@import "Variables.scss";

.program-search {
    display: block;

    .program-search-form {
        --border: 2px solid #{$adtalemBorderGray};
        --padding-block: 0.5rem;
        --padding-inline: 1rem;
        --border-radius: 22px;
        position: relative;

        .program-search-form__search {
            display: flex;

            @media all and (min-width: $screen_md_min) {
                max-width: 372px;
            }

            .search-input {
                flex-grow: 1;
                outline: none;
                background-color: $white;
                color: $adtalemDarkGray;
                border-radius: var(--border-radius) 0 0 var(--border-radius);
                border-block: var(--border);
                border-inline-start: var(--border);
                border-inline-end: none;
                padding-block: var(--padding-block);
                padding-inline: var(--padding-inline);
            }

            .search-icons {
                border-radius: 0 var(--border-radius) var(--border-radius) 0;
                border-block: var(--border);
                border-inline-start: none;
                border-inline-end: var(--border);
                padding-block: var(--padding-block);
                padding-inline-start: 0;
                padding-inline-end: var(--padding-inline);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .program-search-form__results {
            display: none;
            background-color: $white;
            z-index: 10;
            padding: 0;
            list-style-type: none;
            border-radius: 0 0 var(--border-radius) var(--border-radius);
            border-block-end: var(--border);
            border-inline: var(--border);
            width: 100%;
            overflow-x: hidden;
            scrollbar-width: thin;

            @media all and (min-width: $screen_md_min) {
                position: absolute;
                max-height: calc(10 * (2 * var(--padding-inline) + 1rem));
            }

            li {
                a {
                    display: block;
                    color: $black;
                    text-decoration: none;
                    background-color: transparent;
                    padding-block: var(--padding-block);
                    padding-inline: var(--padding-inline);
                }

                &:hover, &[aria-selected='true'] {
                    background-color: $adtalemHoverGray;
                }
            }
        }

        .program-search-form__noresults {
            overflow-wrap: break-word;
            position: absolute;
            background-color: $white;
            z-index: 10;
            border-radius: 0 0 var(--border-radius) var(--border-radius);
            border-block-end: var(--border);
            border-inline: var(--border);
            padding-block-start: var(--padding-block);
            padding-block-end: calc(2 * var(--padding-block));
            padding-inline: var(--padding-inline);
            width: 100%;

            p {
                margin-block-end: 0.5rem;

                :last-child {
                    margin-block-end: 0;
                }
            }

            button.search-reset {
                background-color: transparent;
                border: none;
                padding: 0;
                color: $adtalemCelebrationSlate;
                text-decoration: underline;

                &:hover {
                    color: $adtalemGold;
                }
            }
        }

        &.is-open {
            .program-search-form__search {
                .search-input {
                    border-radius: var(--border-radius) 0 0 0;
                    border-block-end: none;
                }

                .search-icons {
                    border-radius: 0 var(--border-radius) 0 0;
                    border-block-end: none;
                }
            }
        }
    }
}
