﻿@import "../Variables.scss";

.toggle-content-block {
    display: block;

    .toggle-card {
        --gutters: 1.5rem;
        overflow: hidden;

        @media all and (min-width: $screen_md_min) {
            border: 1px solid $adtalemDisabledGray;
            border-radius: 0.5rem;
        }


        &__content {
            @media all and (min-width: $screen_md_min) {
                padding-block: var(--gutters);
                padding-inline: var(--gutters);
            }

            .card-headline {
                scroll-margin-block-start: 1rem;
                color: $adtalemCelebrationSlate;
                margin-block-end: 1rem;
            }

            .card-message {
                *:last-child {
                    margin-block-end: 0;
                    padding-block-end: 0;
                }
            }

            .card-expando {
                scroll-margin-block-start: 1rem;

                &[aria-hidden='false'] {
                    margin-block-start: 1.5rem;
                }

                @media all and (min-width: $screen_md_min) {
                    display: grid;
                    grid-template-rows: 1fr;
                    opacity: 1;
                    transition: opacity 200ms ease-in;

                    &[aria-hidden='true'] {
                        grid-template-rows: 0fr;
                        opacity: 0;
                    }

                    &__items {
                        overflow: hidden;

                        > .block {
                            margin-block: 1.5rem;

                            &:last-child {
                                margin-block-end: 0;
                            }
                        }
                    }
                }
            }
        }

        &__controls {
            color: $adtalemSlateLight;
            background-color: $adtalemMiddleGray;
            font-size: 0.875rem;
            font-weight: $fontWeightBold;
            text-decoration: none;
            text-transform: uppercase;
            display: none;
            border: none;
            width: 100%;
            cursor: pointer;

            &:visited {
                color: $adtalemSlateLight;
            }

            @media all and (min-width: $screen_md_min) {
                padding-block: 1rem;
                padding-inline: var(--gutters);
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                &:hover {
                    color: $adtalemCelebrationSlate;
                }
            }
        }
    }
}
