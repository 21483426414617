﻿@import "../Variables.scss";

.spotlight-block {
    display: block;

    .spotlight-wrapper {
        display: grid;
        grid-template-areas:
            "top" "bottom";
        gap: 2rem;

        @media all and (min-width: $screen_lg_min) {
            grid-template-areas: "left right";
        }

        &.visual-left {
            .spotlight-content {
                grid-area: bottom;
            }

            .spotlight-visual {
                grid-area: top;
            }

            @media all and (min-width: $screen_lg_min) {
                grid-template-columns: 1fr 1fr;

                .spotlight-content {
                    grid-area: right;
                }

                .spotlight-visual {
                    grid-area: left;
                }
            }

            @media all and (min-width: $screen_xl_min) {
                grid-template-columns: 4fr 6fr;
            }
        }

        &.visual-right {
            .spotlight-content {
                grid-area: bottom;
            }

            .spotlight-visual {
                grid-area: top;
            }

            @media all and (min-width: $screen_lg_min) {
                grid-template-columns: 1fr 1fr;

                .spotlight-content {
                    grid-area: left;
                }

                .spotlight-visual {
                    grid-area: right;
                }
            }

            @media all and (min-width: $screen_xl_min) {
                grid-template-columns: 6fr 4fr;
            }
        }

        .spotlight-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            @media all and (min-width: $screen_lg_min) {
                text-align: left;
            }

            &__btn {
                padding-block: 0.825rem;
                padding-inline: 0;
                border: none;
                background-color: transparent;
                color: $adtalemCyan3;
                font-size: 1rem;
                font-weight: $fontWeightSemiBold;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media all and (min-width: $screen_lg_min) {
                    display: none;
                }
            }

            ul {
                li {
                    text-align: left;
                }
            }
        }

        .spotlight-visual {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            border-start-start-radius: 0.5rem;
            border-start-end-radius: 0.5rem;

            @media all and (min-width: $screen_lg_min) {
                border-radius: 0;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .spotlight-terms {
            font-size: 0.875rem;
            font-weight: $fontWeightMedium;
            line-height: 1.25rem;

            @media all and (min-width: $screen_lg_min) {
                grid-column: span 2;
            }
        }
    }

    &.mobile-collapsible {
        .spotlight-content {
            &__body {
                transition: opacity 200ms ease-in;
                display: block;
                height: 0;
                overflow: hidden;

                @media all and (min-width: $screen_lg_min) {
                    height: auto;
                }

                &[aria-hidden='false'] {
                    opacity: 1;
                    height: auto;
                }

                &[aria-hidden='true'] {
                    opacity: 0;
                    height: 0;
                }
            }
        }
    }
}
