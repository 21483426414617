﻿@import "Variables.scss";

#header {
    position: sticky;
    z-index: 1000;
    top: 0;
}

@media (min-width: $screen_lg_min) {
    #header {
        position: static;
    }
}

#request-info-form {
    scroll-margin-block-start: 7rem;

    @media (min-width: $screen_lg_min) {
        scroll-margin-block-start: 0;
    }
}

#backToTop {
    position: fixed;
    bottom: 1.5rem;
    right: 0.75rem;
    cursor: pointer;
    outline: none;
    opacity: 0.5;
    transition: margin-bottom 0.4s ease 0s;
    background: $primary;
    border: 1px solid $white;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba($black, 0.4);
    font-size: 1.2rem;
    color: $white;

    .icon {
        display: block;
        border: solid black;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 8px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        margin: 20px 15px 5px 15px;
    }

    &:focus, &:hover {
        background: $primary-hover;
    }
}

.d--flex {
    display: flex;
}

.hide, .hidden {
    display: none;
}

.overview-header {
    grid-column: 1/span 2;
    grid-row: 1;
}

.overview-blocks {
    grid-column: 1/span 2;
    grid-row: 2;
}

.overview-section {
    display: flex;
    flex-direction: column;

    .container > div,
    .container > p,
    .container > h1,
    .container > h2,
    .container > h3,
    .container > h4,
    .container > h5 {
        width: 100%;
    }
}

.rfi-form {
    width: 100%;
    position: relative;
}

.swiper-wrapper {
    align-items: stretch;
}

.swiper-slide {
    height: auto;
}

.swiper-pagination {
    position: relative;
}

.position-relative {
    position: relative;
}

@media (min-width: $screen_xxl_min) {
    .rfi-form {
        width: 425px;
    }
}

@media (min-width: $screen_xl_min) and (max-width: $screen_xl_max) {
    .rfi-form {
        width: 375px;
    }
}

@media (min-width: $screen_lg_min) and (max-width: $screen_lg_max) {
    .rfi-form {
        width: 316px;
    }
}

@media (min-width: $screen_lg_min) {

    .lg-up--hide {
        display: none;
    }

    .lg-up--d-flex {
        display: flex;
    }

    .lg-up--position-absolute {
        position: absolute;
    }

    .lg-up--position-sticky {
        position: sticky;
        top: 0;
    }

    .overview-section {
        display: grid;
        grid-template-columns: 66.6% auto;

        .block .container > div,
        .block .container > p,
        .block .container > h1,
        .block .container > h2,
        .block .container > h3,
        .block .container > h4,
        .block .container > h5 {
            width: 66.6%;
        }
    }

    .rfi-form.homepage-form {
        margin-top: 0;
        grid-row: 1 / span 3;
    }

    .homepage-form-container {
        left: 65%;
    }

    .rfi-form {
        grid-column: 2;
        grid-row: 2;
        margin-top: -50px;
        z-index: 2;
    }
}

@media (min-width: $screen_md_min) {
    .md-up--hide {
        display: none;
    }

    .md-up--display-flex {
        display: flex;
    }

    .md-up--display-block {
        display: block;
    }
}

.p-32 {
    padding: 2rem;
}

.ml-8 {
    margin-left: .5rem;
}

.w-80 {
    width: 80%;
}

.search {
    padding: .5rem 1rem;
    border-radius: 100px;
    border: 2px solid $adtalemDisabledGray;
    background-color: $white;

}

.search-icon {
    position: absolute;
    right: 1rem;
    background: transparent;
}

.nav-bars.secondary-nav {
    row-gap: 1rem;
}

.nav-bars .nav-item .nav-link, .nav-bars .nav-item .nav-link.active {
    padding: 0 50px;
    color: $black;
}

.nav-bars .nav-item {
    border-right: 1px solid $adtalemDarkGray;
}

.nav-bars .nav-item:last-child {
    border-right: none;
}

.navbar-nav .nav-item.flags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.125rem;

    img {
        height: 45px;
    }
}

.right-0 {
    right: 0;
}

.navbar-toggler {
    padding: 0;
    border: none;
    box-shadow: none;
}

.navigation-top {
    border-top: 1px solid $adtalemDisabledGray;
}

.footer {
    gap: 1rem;
    p {
        margin-bottom: 1rem;
    }

    a {
        color: $white;
    }

    .border-bottom-white {
        border-bottom: 1px solid $white;
    }
}

.content-wrapper > .block > * {
    padding-block: 1.5rem;

    @media all and (min-width: $screen_md_min) {
        padding-block: 3.5rem;
    }
}

.overview-blocks > .block > * {
    padding-block: 1.5rem;
}

.overview-blocks > .block.startdateblock > * {
    padding-block: 1rem;
}