﻿@import "../Variables.scss";

.standard-banner {
    .banner-content-wrapper {
        width: 100%;

        .banner-content {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            text-wrap: balance;

            @media all and (min-width: $screen_md_min) {
                gap: 2rem;
            }

            &__headline {
                margin-block-end: 0;

                &.headline-accent-start, &.headline-accent-center, &.headline-accent-end {
                    padding-block-end: 1.5rem;
                }
            }

            &__visual {
                display:flex;

                .block {
                    width:fit-content;
                }

                .visual-desktop {
                    img {
                        display: none;
                    }
                }

                @media all and (min-width: $screen_md_min) {
                    .visual-mobile {
                        img {
                            display: none;
                        }
                    }

                    .visual-desktop {
                        img {
                            display: block;
                        }
                    }
                }
            }

            &__body, &__terms {
                p:last-child {
                    margin-block-end: 0;
                }
            }

            &__ctas {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 1rem;

                .block {
                    width: fit-content;
                }
            }
        }

        &.is-inset {
            @media all and (min-width: $screen_md_min) {
                padding-inline: 0;
                max-width: 57.5rem;
            }

            .banner-content {
                border-radius: 0.5rem;
                border: 1px solid $adtalemDisabledGray;
                padding-inline: 1rem;
                padding-block: 1rem;

                @media all and (min-width: $screen_md_min) {
                    border: none;
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
                    padding-inline: 2rem;
                    padding-block: 1.5rem;
                    gap: 1.5rem;
                }
            }
        }
    }
}
