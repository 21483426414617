﻿// Responsive breakpoints
$screen_xxl: 1440px !default;
$screen_xxl_min: $screen_xxl !default;

$screen_xl: 1200px !default;
$screen_xl_min: $screen_xl !default;
$screen_xl_max: ($screen_xxl_min - 1) !default;

$screen_lg: 992px !default;
$screen_lg_min: $screen_lg !default;
$screen_lg_max: ($screen_xl_min - 1) !default;

$screen_md: 768px !default;
$screen_md_min: $screen_md !default;
$screen_md_max: ($screen_lg_min - 1) !default;

$screen_sm: 576px !default;
$screen_sm_min: $screen_sm !default;
$screen_sm_max: ($screen_md_min - 1) !default;

$screen_xs: 0px !default;
$screen_xs_min: $screen_xs !default;
$screen_xs_max: ($screen_sm_min - 1) !default;

$min-sizes: (
    "sm": $screen_sm_min, 
    "md": $screen_md_min, 
    "lg": $screen_lg_min, 
    "xl": $screen_xl_min
);

// Color definitions
$black: #000000;
$white: #ffffff;

// Site colors
$primary: #999999;
$primary-hover: #999999;

$adtalemCelebrationSlate: #00334c;
$adtalemSlateLight: #00687F;
$adtalemAlumnusCyan: #006580;
$adtalemCyan2: #2D9CA0;
$adtalemCyan3: #00657c;
$adtalemGold: #fdb71a;
$adtalemTeal300: #EEF4F5;
$adtalemTeal400: #DEE9EB;
$adtalemTeal500: #CFDFE2;
$adtalemTeal800: #008ea9;
$adtalemEmerald: #00a887;
$adtalemDarkBlue: #004d71;
$darkBlueHover: #042C3F;
$adtalemLinkBlue: #0076D6;
$adtalemGeyser: #D6E1E4;
$adtalemGray: #F8F8F8;
$adtalemBorderGray: #B9B9B9;
$adtalemDarkGray: #444444;
$adtalemMiddleGray: #DBDEE0;
$adtalemSlateGray:#5A5D66;
$adtalemEmerald: #00A887;
$adtalemLightGreen:#f2f9fa;

$adtalemSuccess: #00d563;
$adtalemError: #d51a00;

$adtalemDisabledGray: #ccc;
$adtalemHoverGray: #eee;
$adtalemModalBackground: #0b0b0b;

$notoFont: "Noto Serif";
$mulishFont: 'Mulish';
$mulishFontSemiBold: 'Mulish';
$mulishFontBold: 'Mulish';
$mulishFontExtraBold: 'Mulish';

$fontWeightExtraLight: 200;
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;

$fontSizeBody: 18px;

$spacer: 1rem;

$headline-accent-width: 60px;
$headline-accent-height: 2px;

$quoteUrl: "/Assets/icons/quote.svg";
$rightArrowUrl: "/Assets/icons/right_arrow.svg";
$phoneUrl: "/Assets/icons/phone.svg";
$searchUrl: "/Assets/icons/search.svg";

:root {
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-opacit: 1;
    --swiper-pagination-bullet-width: 32px;
    --swiper-pagination-bullet-height: 4px;
    --swiper-pagination-bullet-border-radius: 100px;
}

$flagsImagePath: "~img";

.iti {
    --iti-path-flags-1x: url('/Assets/icons/flags/flags.webp');
    --iti-path-flags-2x: url('/Assets/icons/flags/flags@2x.webp');
    --iti-path-globe-1x: url('/Assets/icons/flags/globe.webp');
    --iti-path-globe-2x: url('/Assets/icons/flags/globe@2x.webp');
}


@import 'intl-tel-input/build/css/intlTelInput.css';

// Mixins
@mixin focusOutline {
    &:focus {
        outline: dashed;
        outline-width: 1px;
        outline-color: #999999;
        outline-offset: -1px;
    }
}

@mixin h-acc-start($important: false) {
    border-image: linear-gradient(to right, $adtalemGold 0%, $adtalemGold $headline-accent-width, transparent $headline-accent-width) 0 0 1 0 / 0 0 $headline-accent-height 0 / 0 0 $headline-accent-height 0 is-important($important);
}

@mixin h-acc-center($important: false) {
    border-image: linear-gradient(to right, transparent calc(50% - $headline-accent-width / 2), $adtalemGold calc(50% - $headline-accent-width / 2), $adtalemGold calc(50% + $headline-accent-width / 2), transparent calc(50% + $headline-accent-width / 2)) 0 0 1 0 / 0 0 $headline-accent-height 0 / 0 0 $headline-accent-height 0 is-important($important);
}

@mixin h-acc-end($important: false) {
    border-image: linear-gradient(to left, $adtalemGold 0%, $adtalemGold $headline-accent-width, transparent $headline-accent-width) 0 0 1 0 / 0 0 $headline-accent-height 0 / 0 0 $headline-accent-height 0 is-important($important);
}

@function is-important($important) {
    @return #{if($important, '!important', '')};
}