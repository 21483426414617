﻿@import "../Variables.scss";

.RFIblock .headline-accent-padding {
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.RFIblock .headline-accent-padding {
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.form-underline {
    border-bottom: 1px solid $adtalemDisabledGray;
}

.info-form {
    z-index: 10;
}

select.info-form-control {
    background-position: 95% !important;
    background: url('data:image/svg+xml,<svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_2_11123)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.284 1.96399H11.315L6.299 6.97999L1.284 1.96399Z" fill="%23333333"/></g><defs><clipPath><rect width="12" height="6" fill="white" transform="translate(0.299988 0.97998)"/></clipPath></defs></svg>');
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-color: $white;
}

.info-form-control {
    width: 100%;
    height: 50px;
    padding: .375rem .75rem;
    color: $adtalemSlateGray;
    background-color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .5rem;
    border: 1px solid $adtalemDisabledGray;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0.01;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
}

[type="checkbox"]:not(:checked) + label:not(.slds-checkbox__label):before {
    content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.66667C4 5.19391 5.19391 4 6.66667 4H17.3333C18.8061 4 20 5.19391 20 6.66667V17.3333C20 18.8061 18.8061 20 17.3333 20H6.66667C5.19391 20 4 18.8061 4 17.3333V6.66667Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H6ZM6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6Z" fill="%23CCCCCC"/></svg>');
    position: absolute;
    left: 0;
    top: 0;
}

[type="checkbox"]:checked + label:not(.slds-checkbox__label):before {
    content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 6.66667C4 5.19391 5.19391 4 6.66667 4H17.3333C18.8061 4 20 5.19391 20 6.66667V17.3333C20 18.8061 18.8061 20 17.3333 20H6.66667C5.19391 20 4 18.8061 4 17.3333V6.66667Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H6ZM6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3H6Z" fill="%2300334C"/><path fill-rule="evenodd" clip-rule="evenodd" d="M17.2071 8.29289C17.5976 8.68342 17.5976 9.31658 17.2071 9.70711L10.5 16.4142L6.79289 12.7071C6.40237 12.3166 6.40237 11.6834 6.79289 11.2929C7.18342 10.9024 7.81658 10.9024 8.20711 11.2929L10.5 13.5858L15.7929 8.29289C16.1834 7.90237 16.8166 7.90237 17.2071 8.29289Z" fill="%2300334C"/></svg>');
    position: absolute;
    left: 0;
    top: 0;
}

.info-form-control::placeholder {
    opacity: 1;
}

.info-radio-control {
    background-color: #fff;
    border: 1px solid #d6e1e4;
    appearance: none;
    border-radius: 100%;
    height: 20px;
    padding: 0;
    width: 20px;
}

.info-radio-control:checked::after {
    background-color: #fbb84b;
    border-radius: 100%;
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    margin: auto;
}

.valid {
    border-left: 1rem solid $adtalemSuccess;
}

.invalid {
    border-left: 1rem solid $adtalemError;
}

.iti__tel-input.invalid {
    border-left: 1px solid $adtalemDisabledGray;
    border-right: 1rem solid $adtalemError;
}

.iti__tel-input.valid {
    border-left: 1px solid $adtalemDisabledGray;
    border-right: 1rem solid $adtalemSuccess;
}

.btn-form-tab {
    width: 100%;
    opacity: 100% !important;
    padding: 2px 0;
    border: none !important;
}

.mobile-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

@media (min-width: $screen_lg_min) {

    .mobile-modal {
        position: relative;
        display: flex;
    }
}

.grecaptcha-badge {
    visibility: hidden !important;
}