@import "Variables.scss";

body {
	font-family: $mulishFont;
	font-size: $fontSizeBody;
	line-height: 28px;
}

.display {
	font-feature-settings: 'liga' off, 'clig' off;
	font-family: $mulishFont;
	font-size: $fontSizeBody;
	font-style: normal;
	font-weight: $fontWeightMedium;
	letter-spacing: 0.9px;
	text-transform: capitalize;
}

.text-noto {
	font-family: $notoFont !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, .text {
	font-feature-settings: 'liga' off, 'clig' off;
	font-family: $notoFont;
	font-style: normal;
	font-weight: $fontWeightMedium;
}

.text {
	font-size: 16px;
	font-weight: 700;
}

h1, .h1 {
	font-size: 28px;
	letter-spacing: -0.25px;
}

h2, .h2 {
	font-size: 24px;
	letter-spacing: -0.25px;
}

h3, .h3 {
	font-size: $fontSizeBody;
	letter-spacing: -0.25px;
}

h4, .h4 {
	font-size: $fontSizeBody;
	font-weight: $fontWeightSemiBold;
	letter-spacing: -0.75px;
}

h5, .h5 {
	font-size: $fontSizeBody;
	font-weight: $fontWeightBold;
	font-family: $mulishFont;
}

@media (min-width: $screen_md_min) {
	.display {
		font-size: 34px;
		font-style: normal;
		font-weight: $fontWeightBold;
		letter-spacing: 1.7px;
	}

	.text {
		font-size: 24px;
		font-weight: 500;
	}

	h1, .h1 {
		font-size: 42px;
	}

	h2, .h2 {
		font-size: 36px;
	}

	h3, .h3 {
		font-size: 34px;
	}

	h4, .h4 {
		font-size: 28px;
		font-weight: $fontWeightMedium;
	}

	h5, .h5 {
		font-size: 24px;
	}
}

body, .body-1, .body-2, .body-3, .body-4, .caption, .body-5 {
	font-family: $mulishFont;
	font-style: normal;
	font-weight: $fontWeightRegular;
}

body, .body-1 {
	font-size: $fontSizeBody;
	line-height: 28px;
}

.body-2 {
	font-size: 16px;
	line-height: 24px;
}

.body-3 {
	font-size: 14px;
	line-height: 24px;
}

.caption, .body-4 {
	font-size: 12px;
	line-height: 20px;
}

.body-5 {
	font-size: 10px;
}

b, .semi-bold {
	font-weight: $fontWeightSemiBold !important;
}

.bold {
	font-weight: $fontWeightBold !important;
}

.italic {
	font-style: italic;
}

.text-underline, .underline {
	text-decoration: underline !important;
}

.text-uppercase, .uppercase {
	text-transform: uppercase !important;
}

.subtitle, .subtitle-1, .subtitle-2, .subtitle-3, .subtitle-4 {
	font-feature-settings: 'liga' off, 'clig' off;
	font-family: $mulishFont;
	font-style: normal;
	font-weight: $fontWeightRegular;
	letter-spacing: 1px;
}

.subtitle, .subtitle-1 {
	font-size: 20px;
	line-height: 32px;
	letter-spacing: 1.25px;
}

.subtitle-2 {
	font-size: $fontSizeBody;
	line-height: 28px;
}

.subtitle-3 {
	font-size: 16px;
	line-height: 28px;
}

.subtitle-4 {
	font-size: 14px;
	line-height: 24px;
}

a, .inline-link {
	text-decoration: underline;
	color: $adtalemCelebrationSlate;
	&:hover {
		color: $adtalemGold;
	}
}

ul.checkmark {
	list-style-image: url('/Assets/icons/checkmark.svg');
}

.content-block-header-large {
	font-family: $mulishFontBold;
	font-size: 36px;
	line-height: 36px;
}