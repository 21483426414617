﻿@import "../Variables.scss";

.hero-header {
    position: relative;

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        user-select: none;
        pointer-events: none;
    }

    .hero-bg-desktop {
        img {
            display: none;
        }
    }

    @media all and (min-width: $screen_md_min) {
        .hero-bg-mobile {
            img {
                display: none;
            }
        }

        .hero-bg-desktop {
            img {
                display: block;
            }
        }
    }

    .hero-header-wrapper {
        z-index: 1;
        position: relative;
    }

    .hero-header-content {
        --gutter-x: 2rem;
        --min-height: 18.75rem;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-block: var(--gutter-x);
        min-height: var(--min-height);
        text-wrap: balance;

        @media all and (min-width: $screen_md_min) {
            max-width: 66.6%;
            --min-height: 22.5rem;
            --gutter-x: 4.5rem;
        }

        &__headline {
            margin-block-end: 0;

            &.headline-accent-start, &.headline-accent-center, &.headline-accent-end {
                padding-block-end: 1rem;
            }
        }

        &__body {
            display: none;

            @media all and (min-width: $screen_md_min) {
                display: block;
            }

            p:last-child {
                margin-block-end: 0;
            }
        }

        &__ctas {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;

            .block {
                width: fit-content;
            }
        }
    }
}
