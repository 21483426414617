﻿@import "Variables.scss";

/* Colors */
.bg-slate {
    background-color: $adtalemCelebrationSlate;
}

.bg-cyan {
    background-color: $adtalemAlumnusCyan;
}

.bg-gold {
    background-color: $adtalemGold !important;
}

.bg-teal-300 {
    background-color: $adtalemTeal300;
}

.bg-teal-400 {
    background-color: $adtalemTeal400;
}

.bg-teal-500 {
    background-color: $adtalemTeal500;
}

.bg-teal-800 {
    background-color: $adtalemTeal800;
}

.bg-teal {
    background-color: $adtalemTeal800;
}

.bg-gray {
    background-color: $adtalemGray;
}

.bg-dark-gray {
    background-color: $adtalemDarkGray;
}

.bg-geyser {
    background-color: $adtalemGeyser;
}

.bg-light-green {
    background-color: $adtalemLightGreen;
}

.text-slate {
    color: $adtalemCelebrationSlate;
}

.text-slate-light {
    color: $adtalemSlateLight;
}

.text-cyan {
    color: $adtalemAlumnusCyan;
}

.text-gold {
    color: $adtalemGold;
}

.text-teal-300 {
    background-color: $adtalemTeal300;
}

.text-teal-400 {
    background-color: $adtalemTeal400;
}

.text-teal-500 {
    background-color: $adtalemTeal500;
}

.text-teal-800 {
    background-color: $adtalemTeal800;
}

.text-teal {
    color: $adtalemTeal800;
}

.text-gray {
    color: $adtalemGray;
}

.text-dark-gray {
    color: $adtalemDarkGray;
}

.text-slate-gray {
    color: $adtalemSlateGray;
}

.i-quote, .i-right-arrow, .i-phone, .i-search {
    display: inline-block;
    background-size: cover;
    height: 1rem;
    width: 1rem;
}

.i-quote {
    -webkit-mask: url($quoteUrl) no-repeat center;
    mask: url($quoteUrl) no-repeat center;
}

.i-right-arrow {
    -webkit-mask: url($rightArrowUrl) no-repeat center;
    mask: url($rightArrowUrl) no-repeat center;
}

.i-phone {
    -webkit-mask: url($phoneUrl) no-repeat center;
    mask: url($phoneUrl) no-repeat center;
}

.i-search {
    -webkit-mask: url($searchUrl) no-repeat center;
    mask: url($searchUrl) no-repeat center;
}

i-small {
    height: .5rem;
    width: .5rem;
}

i-large {
    height: 1.5rem;
    width: 1.5rem;
}

i-extra-large {
    height: 2rem;
    width: 2rem;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.headline-accent-start {
    @include h-acc-start;
}

.headline-accent-center {
    @include h-acc-center;
}

.headline-accent-end {
    @include h-acc-end;
}

@each $name, $size in $min-sizes {
    .headline-accent-start-#{$name} {
        @media all and (min-width: #{$size}) {
            @include h-acc-start(true);
        }
    }

    headline-accent-center-#{$name} {
        @media all and (min-width: #{$size}) {
            @include h-acc-center(true);
        }
    }

    headline-accent-end-#{$name} {
        @media all and (min-width: #{$size}) {
            @include h-acc-end(true);
        }
    }
}

.headline-accent-padding {
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;

    @media all and (min-width: $screen_md_min) {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }
}
/* Buttons */
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $mulishFont;
    font-weight: $fontWeightExtraBold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    border-radius: 100px;
    border: 2px solid transparent;
    padding: 8px 24px;
    text-transform: uppercase;
    word-wrap: break-word;

    &:disabled {
        background-color: $adtalemDisabledGray;
        color: $adtalemCelebrationSlate;
        border: 2px solid transparent;
    }
}

.btn-primary,
.btn-secondary,
.btn-outline-dark,
.btn-outline-light {
    &:active:focus,
    &:focus,
    &:active,
    &:hover {
        transition: background-color 0.3s ease-in;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        background-color: $adtalemEmerald;
        outline: $adtalemEmerald;
        border-color: $adtalemEmerald;
        color: $white;
        fill: $white;
    }
}

.btn-primary {
    background-color: $adtalemGold;
    color: $adtalemCelebrationSlate;
}

.btn-secondary {
    background-color: $adtalemCelebrationSlate;
    color: $white;
}

.btn-outline-dark {
    background-color: transparent;
    color: $adtalemCelebrationSlate;
    border-color: $adtalemCelebrationSlate;
}

.btn-outline-light {
    background: transparent;
    color: $white;
    border-color: $white;
}

.btn-underline-slate, .btn-underline-white {
    text-decoration: none;
    padding: 0;
    font-family: $mulishFont;
    font-weight: $fontWeightExtraBold;
    border-radius: 0;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;

    &:focus {
        box-shadow: none;
        background-color: transparent;
    }

    &:hover {
        transition: background-color 0.3s ease-in;
        border-bottom-color: $adtalemGold
    }
}

.btn-underline-slate {
    color: $adtalemCelebrationSlate;
    border-bottom: 4px solid $adtalemCelebrationSlate;
    &:focus {
        color: $adtalemCelebrationSlate;
    }
}

.btn-underline-white {
    color: $white;
    border-bottom: 4px solid $white;
    &:focus {
        color: $white;
    }
}

.btn-large {
    padding-top: 12px;
    padding-bottom: 12px;
}

.btn-small {
    padding-top: 4px;
    padding-bottom: 4px;
}

.btn-icon {
    padding: 0px 5.75px;
}

.btn-circle {
    padding: 8px 12px !important;
}

.btn-comparison-tab-container {
    padding: 4px 4px;
    background-color: $adtalemTeal500;
    color: $adtalemCelebrationSlate;
    overflow: auto;

    @media (max-width: $screen_md_max) {
        border-radius: 25px;
        flex-direction: column;
    }

    &.disabled {
        opacity: 1;
    }
}

.btn-comparison-tab {
    background-color: $adtalemTeal500;
    color: $adtalemCelebrationSlate;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 20px;

    &:disabled {
        background-color: $adtalemTeal500;
        color: $adtalemCelebrationSlate;
        opacity: 1;
        cursor: default;
    }

    &:not(:only-of-type) {
        &:hover, &[aria-selected='true'] {
            background-color: $white;
            border-color: $white;
            color: $adtalemCelebrationSlate;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
        }
    }
}

.btn-spinner {
    display: flex;
    width: 24px;
    height: 24px;
    border: 4px solid $adtalemDarkGray;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Banners */
.hero-banner-block {
    min-height: 360px;
    height: 360px;
}

.gradient-overlay-ltr {
    background: linear-gradient(225deg, rgba(0, 0, 0, 0.67) 22.93%, rgba(0, 0, 0, 0.00) 77.99%);
    background-blend-mode: multiply;
}

.gradient-overlay-rtl {
    background: linear-gradient(-225deg, rgba(0, 0, 0, 0.67) 22.93%, rgba(0, 0, 0, 0.00) 77.99%);
    background-blend-mode: multiply;
}
/* General */
.rounded-corners {
    border-radius: 8px;
}

.rounded-image {
    border-radius: 50%;
}

.rounded-icon-container {
    border-radius: 20px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.rounded-icon {
    width: 58px;
    max-width: 80px;
    flex-shrink: 0;
}

.icon-sm {
    width: .5rem;
    height: .5rem;
}

.icon {
    width: 1rem;
    height: 1rem;
}

.icon-lg {
    width: 1.5rem;
    height: 1.5rem;
}

.icon-xl {
    width: 2rem;
    height: 2rem;
}

.media-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.block {
    width: 100%;
}

.content-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    img.rounded-image {
        width: 129px;
        height: 129px;
        margin: 8px auto;
    }

    &.with-box-shadow {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    }
}

.testimonial-block {
    img.rounded-image {
        width: 112px;
        height: 112px;
        margin: 8px auto;
        border: 2px solid $white;
        margin-top: calc(-50% - 1.5rem);
    }

    &.testimonial-img-margin {
        margin-top: 56px;
    }
}

.popup-trigger {
    cursor: pointer;
    text-decoration: none;

    &.video-trigger {
        position: relative;

        &:after {
            --cnt-width: 88px;
            --cnt-height: 60px;
            background-color: rgba($black, .7);
            border-radius: 0.875rem;
            color: hsla(0, 0%, 100%, .8);
            content: "▶";
            font-size: 2rem;
            line-height: 1;
            position: absolute;
            text-align: center;
            width: var(--cnt-width);
            height: var(--cnt-height);
            left: calc(50% - var(--cnt-width) / 2);
            top: calc(50% - var(--cnt-height) / 2);
            padding-block-start: 0.825rem;
        }

        &:hover {
            &:after {
                background-color: $black;
                color: $white;
            }
        }
    }
}

.bottom-border {
    height: 2px;
    width: 60px;
    background-color: $adtalemGold;
}

.column {
    flex-direction: column;
}

.block-justify-content-start .block {
    display: flex;
    justify-content: start;
}

.block-justify-content-center .block {
    display: flex;
    justify-content: center;
}

.block-justify-content-end .block {
    display: flex;
    justify-content: end;
}

@media (min-width: $screen_lg_min) {

    .testimonial-block {
        img.rounded-image {
            width: 160px;
            height: 160px;
            border: 4px solid $white;
        }

        &.testimonial-img-margin {
            margin-top: 80px;
        }
    }

    .lg-up--width-75 {
        width: 75% !important;
    }

    .lg-up--row {
        flex-direction: row !important;
    }

    .lg-up--column {
        flex-direction: column !important;
    }

    .lg-up--column-reverse {
        flex-direction: column-reverse !important;
    }

    .lg-up--justify-content-right {
        justify-content: end;
    }

    .lg-up--justify-content-left {
        justify-content: start;
    }

    .lg-up--justify-content-center {
        justify-content: center;
    }
}

.wysiwyg .container {
    margin: 0 !important;
    padding: 0 !important;
}

@media (max-width: $screen_md_max) {
    .md-down--text-center {
        text-align: center !important;
    }
}
// Width
.w-80 {
    width: 80%;
}

@media (min-width: $screen_sm_min) {
    .sm-up--w-80 {
        width: 80% !important;
    }
}
// Padding
.comparison-tab-body {
    padding: 32px 24px;
}

.p-large {
    padding: 16px 24px;
}

.p-medium {
    padding: 16px;
}

.p-small {
    padding: 8px;
}

.p-none {
    padding: 0;
}

@media (min-width: $screen_md_min) {
    .p-large {
        padding: 24px 32px;
    }
}

.px-4-25 {
    padding-left: ($spacer * 2) !important;
    padding-right: ($spacer * 2) !important;
}

.py-4-25 {
    padding-top: ($spacer * 2) !important;
    padding-bottom: ($spacer * 2) !important;
}

.px-4-75 {
    padding-left: ($spacer * 2.5) !important;
    padding-right: ($spacer * 2.5) !important;
}

.py-4-75 {
    padding-top: ($spacer * 2.5) !important;
    padding-bottom: ($spacer * 2.5) !important;
}

.gap-4-25 {
    gap: ($spacer * 2) !important;
}

.gap-4-75 {
    gap: ($spacer * 2.5) !important;
}

.mr-2 {
    margin-right: ($spacer * .5) !important;
}

.mr-3 {
    margin-right: ($spacer * 1) !important;
}

.mb-12 {
    margin-bottom: 12px;
}

p:last-child {
    margin-block-end: 0;
}