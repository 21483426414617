﻿@import "../Variables.scss";

.overview-degree-cards-block {
    display: block;

    .overview-cards-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        @media all and (min-width: $screen_md_min) {
            grid-template-columns: repeat(4, 1fr);
        }

        .overview-card {
            padding-block: 1rem;
            padding-inline: 2.5rem;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            background-color: $white;
            color: $adtalemCelebrationSlate;
            font-size: 1rem;
            font-weight: $fontWeightBold;
            line-height: 1.5;
            text-transform: uppercase;
            text-decoration: none;
            cursor: pointer;
            text-align: center;
            min-width: 160px;

            &:hover {
                background-color: $adtalemHoverGray;
            }
        }
    }
}
