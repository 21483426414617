﻿@import "../Variables.scss";

.link-list {
    &__headline {
        color: $adtalemCelebrationSlate;
        margin-block-end: 1rem;
        padding-block-end: 1rem;
    }

    &__message {
        margin-block-end: 1.5rem;

        *:last-child {
            margin-block-end: 0;
            padding-block-end: 0;
        }
    }

    &__subheadline {
        color: $adtalemCelebrationSlate;
        font-size: 0.75rem;
        text-transform: uppercase;
        margin-block-end:0;
    }

    &__links {
        list-style-type: none;
        padding-inline: 0;
        margin-block-end: 0;

        li {
            border-bottom: 1px solid $adtalemDisabledGray;
        }

        a {
            color: $adtalemSlateLight;
            font-size: 0.925rem;
            line-height: 0.95rem;
            font-weight: $fontWeightSemiBold;
            text-decoration: none;
            text-underline-offset: 2px;
            padding-block: 1rem;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            span {
                text-decoration: underline;
            }
        }
    }
}
