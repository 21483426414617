﻿@import '../Variables.scss';
.pl-border {
    border: 1px solid $adtalemDisabledGray;
    border-radius: 8px;
    text-decoration: none;
    width: 30%;
}

.pl-learn-more {
    padding: 16px 24px;
}

.pl-border-bottom {
    border-radius: 0 0 8px 8px;
}

.pl-gap {
    gap: 32px;
}

.pl-swiper {
    min-width: 300px;
}