﻿@import "../Variables.scss";

.accordion-block {
    display: block;

    .accordion {
        display: flex;
        flex-direction: column;

        & > .accordion__headline {
            padding-block-end: 1rem;
            margin-block-end: 1.5rem;

            @media all and (min-width: $screen_md_min) {
                padding-block-end: 1.5rem;
                margin-block-end: 2.25rem;
            }
        }

        & > .accordion__message {
            margin-block-end: 1rem;

            @media all and (min-width: $screen_md_min) {
                margin-block-end: 1.5rem;
            }

            *:last-child {
                padding-block-end: 0;
                margin-block-end: 0;
            }
        }

        & > .accordion__cards {
            display: grid;

            > * > .accordion-card, > .accordion-card {
                overflow: hidden;

                > .accordion-card__title {
                    font-size: 1rem;
                    font-weight: $fontWeightMedium;
                    line-height: 1.5rem;
                    text-decoration: none;
                    border: none;
                    width: 100%;
                    text-align:left;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap:1rem;
                    outline-offset: -2px;
                    padding-inline: 0;
                    background-color: transparent;
                }

                > .accordion-card__panel {
                    display: grid;
                    grid-template-rows: 1fr;

                    &[aria-hidden='true'] {
                        grid-template-rows: 0fr;
                    }

                    > .panel-wrapper {
                        overflow: hidden;

                        > .panel-content {
                            > .block {
                                margin-block: 1.5rem;
                            }
                        }
                    }
                }
            }

            &.animated {
                > * > .accordion-card, > .accordion-card {
                    > .accordion-card__panel {
                        transition: grid-template-rows 200ms ease-in-out;
                    }
                }
            }

            &.one-column {
                grid-template-columns: 1fr;
            }

            &.two-columns {
                grid-template-columns: 1fr;

                @media all and (min-width: $screen_lg_min) {
                    grid-template-columns: 1fr 1fr;
                }
            }

            &.bordered {
                column-gap: 1rem;

                > * > .accordion-card, > .accordion-card {
                    background-color: transparent;

                    > .accordion-card__title {
                        padding-block: 1rem;
                    }
                }
            }

            &.bordered.light {
                > * > .accordion-card, > .accordion-card {
                    border-bottom: 1px solid $white;

                    > .accordion-card__title {
                        color: $white;
                    }
                }

                > *:first-child > .accordion-card, > .accordion-card:first-child {
                    border-top: 1px solid $white;
                }

                &.two-columns {
                    > *:nth-child(2) > .accordion-card, > .accordion-card:nth-child(2) {
                        border-top: 1px solid $white;
                    }
                }
            }

            &.bordered.dark {
                > * > .accordion-card, > .accordion-card {
                    border-bottom: 1px solid $adtalemBorderGray;

                    > .accordion-card__title {
                        color: $adtalemCyan3;
                    }
                }

                > *:first-child > .accordion-card, > .accordion-card:first-child {
                    border-top: 1px solid $adtalemBorderGray;
                }

                &.two-columns {
                    > *:nth-child(2) > .accordion-card, > .accordion-card:nth-child(2) {
                        border-top: 1px solid $adtalemBorderGray;
                    }
                }
            }

            &.pill {
                gap: 1rem;

                > * > .accordion-card, > .accordion-card {
                    background-color: $white;
                    border-radius: 0.5rem;

                    > .accordion-card__title {
                        color: $adtalemSlateLight;
                        background-color: $white;
                        padding-inline: 1rem;
                        padding-block: 1.5rem;
                        transition: all 100ms ease-in-out;
                        transition-property: color, background-color;

                        &:hover, &[aria-expanded='true'] {
                            color: $white;
                            background-color: $adtalemSlateLight;
                        }
                    }
                }
            }

            &.pill.light {
                > * > .accordion-card, > .accordion-card {
                    border: 1px solid $adtalemDisabledGray;
                }
            }

            &.pill.dark {
                > * > .accordion-card, > .accordion-card {
                    border: 1px solid $adtalemBorderGray;
                }
            }
        }

        & > .accordion__footnote {
            margin-block-start: 1rem;

            @media all and (min-width: $screen_md_min) {
                margin-block-start: 1.5rem;
            }

            > *:last-child {
                padding-block-end: 0;
                margin-block-end: 0;
            }
        }

        & > .accordion__ctas {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1rem;
            margin-block-start: 1rem;

            @media all and (min-width: $screen_md_min) {
                margin-block-start: 1.5rem;
            }

            .block {
                width: fit-content;
            }
        }
    }
}
