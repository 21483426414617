﻿@import "../Variables.scss";

.tabbed-content-block {
    display: block;

    .tabbed-content {
        &__headline {
            padding-block-end: 1rem;
            margin-block-end: 1.5rem;

            @media all and (min-width: $screen_md_min) {
                padding-block-end: 1.5rem;
                margin-block-end: 2.25rem;
            }
        }

        &__message {
            margin-block-end: 1rem;

            @media all and (min-width: $screen_md_min) {
                margin-block-end: 1.5rem;
            }

            *:last-child {
                margin-block-end: 0;
                padding-block-end: 0;
            }
        }

        &__tab-controls {
            list-style-type: none;
            padding: 0;

            .tab-control {
                button {
                    display: block;
                    border: none;
                    width: 100%;
                    height: 100%;
                    border-radius: 0.5rem;
                    text-align: center;
                    letter-spacing: 0.05em;
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            &.tabs-teal {
                display: grid;
                gap: 1rem;
                grid-template-columns: repeat(auto-fit, minmax(min(10rem, 100%), 1fr));
                grid-auto-rows: 1fr;
                margin-block-end: 1.5rem;

                .tab-control {
                    button {
                        padding-block: 1rem;
                        padding-inline: 0;
                        text-align: center;
                        vertical-align: middle;
                        color: $white;
                        background-color: $adtalemSlateLight;
                        text-transform: uppercase;
                        min-height: 5rem;
                        font-weight: $fontWeightSemiBold;
                        font-size: 0.875rem;
                        line-height: 1.5rem;
                        scroll-margin-block-start: 1rem;

                        @media all and (min-width: $screen_md_min) {
                            font-weight: $fontWeightBold;
                            font-size: 1rem;
                        }

                        &[aria-selected='true'], &:hover {
                            color: $adtalemCelebrationSlate;
                            background-color: $adtalemGold;
                        }
                    }
                }
            }

            &.tabs-gray {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                overscroll-behavior-inline: contain;
                scroll-snap-type: inline mandatory;
                scrollbar-width: thin;
                gap: 1rem;
                padding-block-end: 1rem;

                .tab-control {
                    scroll-snap-align: start;
                    width: 100%;

                    button {
                        padding-block: 0.625rem;
                        padding-inline: 1rem;
                        color: $adtalemCelebrationSlate;
                        background-color: $adtalemMiddleGray;
                        font-weight: $fontWeightBold;
                        white-space: nowrap;

                        &[aria-selected='true'], &:hover {
                            color: $white;
                            background-color: $adtalemSlateLight;
                        }
                    }
                }
            }
        }

        &__gap {
            gap: 1.5rem;

            @media all and (min-width: $screen_md_min) {
                gap: 3.5rem;
            }
        }

        &__tab {
            display: grid;
            margin-block-start: 1.5rem;

            @media all and (min-width: $screen_md_min) {
                //gap: 3.5rem;
                margin-block-start: 3.5rem;
            }
        }

        &__footnote {
            margin-block-start: 1rem;

            @media all and (min-width: $screen_md_min) {
                margin-block-start: 1.5rem;
            }

            *:last-child {
                margin-block-end: 0;
                padding-block-end: 0;
            }
        }

        &__ctas {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            margin-block-start: 1rem;

            @media all and (min-width: $screen_md_min) {
                margin-block-start: 1.5rem;
            }

            .block {
                width: fit-content;
            }
        }
    }
}
